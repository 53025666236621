const constants_list = {
    ganache: {
        CHEF: '0x318Fa2fE55963ae1cAc2E92E03B0778871AB757C',
        BRY_BNB_LP: '0x341F8f22f1A9aD687Aa730D00fFF938b8F183e11',
        BRY_TOKEN: '0x6c4A99B7c7DAEe33e07b816C023C966FE62D66c5',
        WBNB: '0xA051330534db1E58c7736c732B5bc5ce0eB78f0B',
        BRY_STAKE: ''
    },
    testnet: {
        CHEF: '0xE783a19751dd9c4a692fA9066060c67842f96d53',
        BRY_BNB_LP: '0xB6C3024eD79d5aD31eBB5e462bD7b1bB1D6cb11c',
        BRY_TOKEN: '0x191C9511F346f017e9618f3F0b7Ba32E0E4A1E10', // 0xe81323AE0C0992cdbad722E7651984829B2987f8
        WBNB: '0x6c4A99B7c7DAEe33e07b816C023C966FE62D66c5',
        BRY_STAKE: '0x680A36DE05D23FFe090D37B51f9a3EBc2bAc6B88'
    },
    mainnet: {
        POOLS: [{
            CHEF: '0x8cE94299a46f96936f757F88501A858087cED915',
            LP: '0x014a72Cc99368f19eE8E0Cf9f1aE449684290cD6', // bry usdt lp
        }, {
            CHEF: '0x6ca401055cd38ad93ef621fb869c374d4886d99d',
            LP: '0x21dD71aB78EDE3033c976948f769D506E4F489eE', // v2 lp new
        }, {
            CHEF: '0xa1a240DbC0bC7aeD2065700ecD1443df23B8a0bA',
            LP: '0x21dD71aB78EDE3033c976948f769D506E4F489eE', // v2 lp
        }, {
            CHEF: '0x81c9E81b47eA9D8dc7A4aE66A4e28407074e3cCa',
            LP: '0x5E3CD27F36932Bc0314aC4e2510585798C34a2fC', // v1 lp
        }],
        BRY_TOKEN: '0xf859Bf77cBe8699013d6Dbc7C2b926Aaf307F830',
        WBNB: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
        BRY_STAKE: '0x6DB62e510A24600a373a4C3a918baD9DDaA9C318' // prod test
    }
};

const NETWORK = process.env.VUE_APP_NETWORK;
module.exports = constants_list[NETWORK];
