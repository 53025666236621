<template>
  <div class="alert-modal" v-show="show">
    <div class="mask"></div>
    <div class="cont">
      <div class="title">
        <h3>Your wallet</h3>
        <div class="btn-close" @click="onClose">
          <svg viewBox="0 0 24 24" color="primary" width="20px" xmlns="http://www.w3.org/2000/svg" class="sc-bdfBwQ fxYfwL"><path d="M18.3 5.70997C17.91 5.31997 17.28 5.31997 16.89 5.70997L12 10.59L7.10997 5.69997C6.71997 5.30997 6.08997 5.30997 5.69997 5.69997C5.30997 6.08997 5.30997 6.71997 5.69997 7.10997L10.59 12L5.69997 16.89C5.30997 17.28 5.30997 17.91 5.69997 18.3C6.08997 18.69 6.71997 18.69 7.10997 18.3L12 13.41L16.89 18.3C17.28 18.69 17.91 18.69 18.3 18.3C18.69 17.91 18.69 17.28 18.3 16.89L13.41 12L18.3 7.10997C18.68 6.72997 18.68 6.08997 18.3 5.70997Z"></path></svg>
        </div>
      </div>
      <div class="article">
        <div class="text">{{address}}</div>
        <div class="d-flex">
          <a class="link" :href="'https://bscscan.com/address/'+address" target="_blank">View on BscScan
            <svg viewBox="0 0 24 24" color="primary" width="20px" xmlns="http://www.w3.org/2000/svg" class="sc-bdfBwQ gqbVmy"><path d="M18 19H6C5.45 19 5 18.55 5 18V6C5 5.45 5.45 5 6 5H11C11.55 5 12 4.55 12 4C12 3.45 11.55 3 11 3H5C3.89 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V13C21 12.45 20.55 12 20 12C19.45 12 19 12.45 19 13V18C19 18.55 18.55 19 18 19ZM14 4C14 4.55 14.45 5 15 5H17.59L8.46 14.13C8.07 14.52 8.07 15.15 8.46 15.54C8.85 15.93 9.48 15.93 9.87 15.54L19 6.41V9C19 9.55 19.45 10 20 10C20.55 10 21 9.55 21 9V4C21 3.45 20.55 3 20 3H15C14.45 3 14 3.45 14 4Z"></path></svg>
          </a>
          <!-- <div class="link">
            Copy Address
            <svg viewBox="0 0 24 24" width="20px" color="primary" xmlns="http://www.w3.org/2000/svg" class="sc-bdfBwQ gqbVmy"><path d="M15 1H4C2.9 1 2 1.9 2 3V16C2 16.55 2.45 17 3 17C3.55 17 4 16.55 4 16V4C4 3.45 4.45 3 5 3H15C15.55 3 16 2.55 16 2C16 1.45 15.55 1 15 1ZM19 5H8C6.9 5 6 5.9 6 7V21C6 22.1 6.9 23 8 23H19C20.1 23 21 22.1 21 21V7C21 5.9 20.1 5 19 5ZM18 21H9C8.45 21 8 20.55 8 20V8C8 7.45 8.45 7 9 7H18C18.55 7 19 7.45 19 8V20C19 20.55 18.55 21 18 21Z"></path></svg>
          </div> -->
        </div>
        <div class="d-center">
          <button class="logout" @click="doLogout">Logout</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "modal-connected",
  computed: {
    show() {
      return this.$store.state.modalConnected
    },
    address() {
      return this.$store.state.address
    }
  },
  methods: {
    onClose() {
      this.$store.commit('modalConnected');
    },
    doLogout() {
      this.$store.commit('modalConnected');
      this.$store.commit('logout');
    }
  }
}
</script>

<style lang="less" scoped>
@import "../assets/style/font.less";
.alert-modal {
  .mask {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .5);
    z-index: 9;
  }
  .cont {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9;
    width: auto;
    min-width: 360px;
    max-width: 100%;
    box-shadow: rgb(14 14 44 / 10%) 0px 20px 36px -8px, rgb(0 0 0 / 5%) 0px 1px 1px;
    border-radius: 32px;
    background-color: #fff;
  }
  .title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid rgb(233, 234, 235);
    -webkit-box-align: center;
    padding: 12px 24px;
    h3 {
      font-size: 20px;
      font-weight: 600;
      line-height: 1.1;
      color: rgb(69, 42, 122);
      margin-bottom: 0;
    }
    .btn-close {
      width: 48px;
      height: 48px;
      color: rgb(31, 199, 212);
      cursor: pointer;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      svg {
        fill: rgb(31, 199, 212);
        flex-shrink: 0;
      }
    }
  }
  .article {
    display: flex;
    flex-direction: column;
    padding: 24px;
    .text {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-bottom: 8px;
      color: rgb(69, 42, 122);
      font-weight: 600;
      line-height: 1.5;
      font-size: 20px;
    }
  }
  .d-flex {
    display: flex;
    margin-bottom: 32px;
    .link {
      display: flex;
      -webkit-box-align: center;
      align-items: center;
      width: fit-content;
      color: rgb(31, 199, 212);
      font-size: 14px;
      font-weight: 600;
      line-height: 1.5;
      margin-right: 16px;
      cursor: pointer;
    }
    svg {
      fill: rgb(31, 199, 212);
      flex-shrink: 0;
      margin-left: 4px;
    }
  }
  .d-center {
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
  }
  .logout {
    -webkit-box-align: center;
    align-items: center;
    background-color: transparent;
    border: 2px solid rgb(31, 199, 212);
    border-radius: 16px;
    box-shadow: none;
    color: rgb(31, 199, 212);
    cursor: pointer;
    display: inline-flex;
    font-family: inherit;
    font-size: 16px;
    font-weight: 600;
    width: max-content;
    height: 32px;
    line-height: 1;
    letter-spacing: 0.03em;
    -webkit-box-pack: center;
    justify-content: center;
    outline: 0px;
    padding: 0px 16px;
    transition: background-color 0.2s ease 0s;
    opacity: 1;
    &:hover {
      background-color: transparent;
      border-color: rgb(83, 222, 233);
    }
  }
}

</style>