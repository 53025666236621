import Vue from 'vue'
import Vuex from 'vuex'
import api from '../api/index'

import BN from 'bignumber.js'
const ZERO = new BN(0);

import constants from '../constants/index'


Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    modalConnect: false,
    modalConnected: false,
    modalCode: false,

    login: false,
    address: '',

    user: [{
      staked: 0,
      earned: 0,
      balance: 0
    },{
      staked: 0,
      earned: 0,
      balance: 0
    },{
      staked: 0,
      earned: 0,
      balance: 0
    },{
      staked: 0,
      earned: 0,
      balance: 0
    }],

    pools: [{
      id: 0,
      lp: 'BRY_USDT_LP',
      lpAddr: constants.POOLS[0].LP,
      chefAddress: constants.POOLS[0].CHEF,
      pancakeUrl: 'https://exchange.pancakeswap.finance/#/add/0x55d398326f99059fF775485246999027B3197955/0xf859Bf77cBe8699013d6Dbc7C2b926Aaf307F830',
      bscScanUrl: 'https://bscscan.com/address/0x014a72Cc99368f19eE8E0Cf9f1aE449684290cD6#code',
      name: 'BRY-USDT LP',
      token: 'BRY',
      tokenAddr: constants.BRY_TOKEN,
      totalStakedLP: ZERO,
      apy: ZERO,
      multipler: 0,
      bnb: false,
      closing: false,
      isEW: false,

      connector: '',
      rpc: '',
    }, {
      id: 1,
      lp: 'BRY_BNB_LPV2',
      lpAddr: constants.POOLS[1].LP,
      chefAddress: constants.POOLS[1].CHEF,
      pancakeUrl: 'https://exchange.pancakeswap.finance/#/add/0x55d398326f99059fF775485246999027B3197955/0xf859Bf77cBe8699013d6Dbc7C2b926Aaf307F830',
      bscScanUrl: 'https://bscscan.com/address/0x014a72Cc99368f19eE8E0Cf9f1aE449684290cD6#code',
      name: 'BRY-BNB LP New',
      token: 'BRY',
      tokenAddr: constants.BRY_TOKEN,
      totalStakedLP: ZERO,
      apy: ZERO,
      multipler: 0,
      bnb: true,
      closing: false,
      isEW: false,
      fromOracle: true,

      connector: '',
      rpc: '',
    }, {
      id: 2,
      lp: 'BRY_BNB_LPV2',
      lpAddr: constants.POOLS[2].LP,
      chefAddress: constants.POOLS[2].CHEF,
      pancakeUrl: 'https://exchange.pancakeswap.finance/#/add/ETH/0xf859Bf77cBe8699013d6Dbc7C2b926Aaf307F830',
      bscScanUrl: 'https://bscscan.com/address/0x21dD71aB78EDE3033c976948f769D506E4F489eE#code',
      name: 'BRY-BNB LP V2',
      token: 'BRY',
      tokenAddr: constants.BRY_TOKEN,
      totalStakedLP: ZERO,
      apy: ZERO,
      multipler: 0,
      bnb: true,
      closing: true,
      isEW: false,

      connector: '',
      rpc: '',
    }, {
      id: 3,
      lp: 'BRY_BNB_LP',
      lpAddr: constants.POOLS[3].LP,
      chefAddress: constants.POOLS[3].CHEF,
      pancakeUrl: 'https://exchange.pancakeswap.finance/#/add/ETH/0xf859Bf77cBe8699013d6Dbc7C2b926Aaf307F830',
      bscScanUrl: 'https://bscscan.com/address/0x5E3CD27F36932Bc0314aC4e2510585798C34a2fC#code',
      name: 'BRY-BNB LP V1',
      token: 'BRY',
      tokenAddr: constants.BRY_TOKEN,
      totalStakedLP: ZERO,
      apy: ZERO,
      multipler: 0,
      bnb: true,
      closing: true,
      isEW: true,

      connector: '',
      rpc: '',
    }],

    bryStake: {
      staked: 0,
      earned: 0,
      balance: 0,
      totalStaked: ZERO,
      apy: ZERO
    }
  },
  mutations: {
    modalConnect(state) {
      state.modalConnect = !state.modalConnect
    },
    modalConnected(state) {
      state.modalConnected = !state.modalConnected
    },
    modalCode(state) {
      state.modalCode = !state.modalCode
    },
    login(state, data) {
      state.login = true
      state.modalConnect = false
      state.address = data.account
      state.connector = data.wallet
      state.rpc = data.rpc
    },
    logout(state) {
      state.login = false
      state.address = ''
    },
    updatePool(state, data) {
      state.pools[data.i].totalStakedLP = data.totalStakedLP
      state.pools[data.i].apy = data.apy
      state.pools[data.i].multipler = data.multipler
      state.pools[data.i].isEW = data.isEW
    },
    updateUser(state, data) {
      state.user[data.i].balance = data.balance
      state.user[data.i].earned = data.earned
      state.user[data.i].staked = data.staked
    },
    updateBryInfo(state, info) {
      state.bryStake.totalStaked = info.totalStaked;
      state.bryStake.apy = info.apy;
    },
    updateUserBryInfo(state, info) {
      state.bryStake.staked = info.staked;
      state.bryStake.earned = info.earned;
      state.bryStake.balance = info.balance;
    }
  },
  actions: {
    async login(context, data) {
      context.commit('login', data)
      await fetchPoolInfo();
      // window.ethereum.on('accountsChanged', async function(new_accounts) {
      //   context.commit('login', new_accounts[0])
      //   await fetchPoolInfo();
      // })
    }
  }
})

async function fetchPoolInfo() {
  // fetch lp pool info
  for (let i = 0; i < store.state.pools.length; ++i) {
    let pool = store.state.pools[i]
    let totalStakedLP = await api.balanceOf(pool.lpAddr, pool.chefAddress);
    let multipler = pool.fromOracle ? 1 : await api.getMultipler(i);
    let isEW = await api.isEmergencyWithdraw(i);
    let apy = 0;
    try {
      apy = await api.getAPY(pool);
      // console.log('apy=' + apy);
    } catch (e) {
      console.error(e);
    }

    store.commit('updatePool', {i, totalStakedLP, apy, multipler, isEW});
    if (store.state.address !== '') {
      let balance = await api.balanceOf(pool.lpAddr, store.state.address);
      let earned = await api.pendingReward(i, pool.tokenAddr, store.state.address);
      let staked = await api.getStakedLp(i, pool.lpAddr, store.state.address);
      store.commit('updateUser', {i, balance, earned, staked})
    }
  }
  // fetch bry stake info
  let bryInfo = await api.getBryInfo();
  store.commit('updateBryInfo', bryInfo);
  if (store.state.address !== '') {
    let userInfo = await api.getUserBryInfo(store.state.address);
    store.commit('updateUserBryInfo', userInfo);
  }
}

// first init
fetchPoolInfo();

setInterval(async function() {
  await fetchPoolInfo()
}, 5000)


export default store
