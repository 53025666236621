import { render, staticRenderFns } from "./modal-code.vue?vue&type=template&id=4ea53776&scoped=true&"
import script from "./modal-code.vue?vue&type=script&lang=js&"
export * from "./modal-code.vue?vue&type=script&lang=js&"
import style0 from "./modal-code.vue?vue&type=style&index=0&id=4ea53776&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4ea53776",
  null
  
)

export default component.exports