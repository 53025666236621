<template>
  <div id="app">
    <my-header></my-header>
    <div class="notice d-none d-md-block">
      <span>Berry Data Contract Address: </span>
      <span>0xf859Bf77cBe8699013d6Dbc7C2b926Aaf307F830</span>
      <i class="copy" @click="onCopy"/>
    </div>
    <router-view/>
    <my-footer></my-footer>
    <b-alert
        :show="dismissCountDown"
        variant="success"
        fade
        @dismissed="dismissCountDown=0"
        @dismiss-count-down="countDownChanged">Successfully copied
    </b-alert>
    <modal-connect></modal-connect>
    <modal-connected></modal-connected>
    <modal-code></modal-code>
  </div>
</template>

<script>
import MyHeader from "@/components/my-header";
import MyFooter from "@/components/my-footer";
import ModalConnect from "@/components/modal-connect";
import ModalConnected from "@/components/modal-connected";
import ModalCode from "@/components/modal-code";

export default {
  components: {MyFooter, MyHeader, ModalConnect, ModalCode, ModalConnected},
  data() {
    return {
      dismissCountDown: 0,
      dismissSecs: 3,
    }
  },
  methods: {
    showAlert() {
      this.dismissCountDown = this.dismissSecs
    },
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown
    },
    onCopy() {
      let self = this;
      this.$copyText("0xf859Bf77cBe8699013d6Dbc7C2b926Aaf307F830").then(res => {
        self.dismissCountDown = self.dismissSecs
      }, err => {
        console.log(err, 'Copy Failed')
      })
    },
  }
}
</script>


<style lang="less">
* {
  //word-break: break-all;
  font-family: Arial;
  font-size: 16px;
  color: #2B0629;

}


body {
  width: 100vw;
  overflow-x: hidden;
}

#app {
  width: 100vw;
  @media (min-width: 1200px) {
    .container, .container-sm, .container-md, .container-lg, .container-xl {
      max-width: 1200px;
    }
  }

  .d-flex {
    display: flex;
  }

  .flex-row {
    flex-direction: row;
  }

  .flex-column {
    flex-direction: column;
  }

  .justify-center {
    justify-content: center;
  }

  .align-center {
    align-items: center;
  }

  .mr-10 {
    margin-right: 10px;
  }

  .ml-10 {
    margin-left: 10px;
  }

  .pointer {
    cursor: pointer;
  }

  .notice {
    color: #fff;
    line-height: 36px;
    background: #5B0659;
    font-size: 12px;
    text-align: center;
    padding: 0 16px;
    .copy {
      display: inline-flex;
      width: 12px;
      height: 12px;
      background-image: url("./assets/copy.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
      cursor: pointer;
      margin-left: 4px;
    }
    span {
      color: #fff;
      font-size: 14px;
    }
  }

  .alert {
    position: fixed;
    top: 40px;
    left: 50%;
    width: 600px;
    max-width: 100%;
    transform: translateX(-50%);
    color: #000;
    background-color: #D9D9D9;
  }

  .carousel-indicators li {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    margin-left: 12.5px;
    margin-right: 12.5px;
  }

}


.gray-btn {
  max-width: 300px;
  height: 60px;
  border-radius: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #6D6D6D;
  font-size: 16px;
  color: #fff;
  cursor: pointer;
  margin: 44px auto;
}


@media (min-width: 576px) {
  .nav-item {
    margin-right: 10px;

    &:first-child {
      margin-left: 10px;
    }
  }
}

@media (min-width: 768px) {
  .navbar-nav:first-child {
    text-align: left !important;
  }

  .nav-link {
    text-align: left;
  }

  .nav-item {
    //flex: 1;
    //margin-right: 40px;
    //&:first-child{
    //    margin-left: 60px;
    //}
  }


}

@media (max-width: 768px) {
  #app .notice {
    text-align: left;
    line-height: 20px;
  }
}

@media (min-width: 992px) {
  .nav-item {
    //flex: 1;
    margin-right: 6%;

    &:first-child {
      margin-left: 4%;
    }
  }

}


</style>
