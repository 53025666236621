<template>
  <b-navbar toggleable="sm" id="header" :class="{dark: isDark}">
    <b-container class="header-container">
      <b-navbar-brand href="#">
        <img v-if="isDark" src="../assets/images/logo1.png"/>
        <img v-else src="../assets/images/logo.png"/>
      </b-navbar-brand>
      <b-navbar-toggle target="nav-collapse arrow-up"></b-navbar-toggle>
      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav class="ml-auto">
          <b-nav-item href="#">
            <router-link to="/home" active-class="active" class="head-nav">Home</router-link>
          </b-nav-item>
          <b-nav-item href="#">
            <router-link to="/farm" active-class="active" class="head-nav ">Farm</router-link>
          </b-nav-item>
          <!-- <b-nav-item href="#">
            <router-link to="/data-feed/price" active-class="active" class="head-nav ">Data Feed</router-link>
          </b-nav-item> -->
          <b-nav-item href="#" class="nav-datafeed">
            <div class="head-nav" :class="{active: isActiveData}">Data Feed</div>
            <div class="menu">
              <router-link to="/data-feed/price" class="menu-item">Price</router-link>
              <router-link to="/data-feed/yield" class="menu-item">Yield</router-link>
            </div>
            <div class="m-menu d-md-none">
              <router-link to="/data-feed/price" class="menu-item">Price</router-link>
              <router-link to="/data-feed/yield" class="menu-item">Yield</router-link>
            </div>
          </b-nav-item>
          <b-nav-item href="https://docs.berrydata.co" target="_blank">
            <a class="head-nav">Documentation</a>
          </b-nav-item>
        </b-navbar-nav>
        <b-navbar-nav class="ml-100">
          <div class="unlock" v-if="isConnect" @click="handleConnected">{{ address }}</div>
          <div class="unlock" @click="handleConnect" v-if="!isConnect">Unlock Wallet</div>
          <div class="unlock buy" @click="buyBRY()">Buy BRY</div>
        </b-navbar-nav>
      </b-collapse>
    </b-container>
  </b-navbar>
</template>

<script>

export default {
  name: "my-header",
  components: {},
  data() {
    return {
      isDark: true,
      isActiveData: false
    }
  },
  computed: {
    isConnect() {
      return this.$store.state.login;
    },
    address() {
      let a = this.$store.state.address;
      if (!a || a.length === 0) return '';
      return a.substring(0, 6) + '...' + a.substring(a.length - 4)
    }
  },
  methods: {
    handleConnect() {
      this.$store.commit('modalConnect');
    },
    handleConnected() {
      this.$store.commit('modalConnected');
    },
    buyBRY() {
      window.open("https://exchange.pancakeswap.finance/#/swap?outputCurrency=0xf859bf77cbe8699013d6dbc7c2b926aaf307f830", "_blank");
    }
  },
  watch: {
    '$route' (to, from) {
      // console.log(to, from)
      this.isDark = to.name === 'home'
      if(/dataFeedPrice|dataFeedYield/.test(to.name)) {
        this.isActiveData = true
      } else {
        this.isActiveData = false
      }
    }
  }
}
</script>

<style lang="less">
#header {
  background-color: #880D85;
  color: #fff;
  min-height: 84px;
  &.dark {
    background-color: #180217;
  }
  .ml-100 {
    margin-left: 160px;
  }
  .navbar-toggler {
    border: none;
  }

  .navbar-toggler-icon {
    background: url("../assets/images/menu.png") no-repeat;
  }
}

.navbar-nav {
  justify-content: left;
}



.header-container {
}

.unlock {
  height: 44px;
  padding: 0 20px;
  min-width: 144px;
  border-radius: 44px;
  line-height: 44px;
  border: 1px solid #4B9629;
  box-sizing: border-box;
  background-color: #4B9629;
  text-align: center;
  cursor: pointer;
  font-size: 16px;
  color: #fff;
  &:hover {
    background-color: #60B738;
    border: 1px solid #60B738;
  }

}

.unlock.buy {
  margin-left: 20px;
  background-color: transparent;
  border-color: #fff;
}

.head-nav {
  text-align: center;
  //width: 57px;
  height: 20px;
  font-size: 16px;
  font-weight: 500;
  color: #fff;
  word-break: keep-all;
  white-space: nowrap;
  &:hover {
    text-decoration: none;
    color: #fff;
  }
  &.active {
    //color: #4B9629;
    font-size: 18px;
    font-weight: bold;
    position: relative;
    &:before{
      content: '';
      position: absolute;
      bottom: -10px;
      left: 10px;
      right: 10px;
      height: 4px;
      background-color: #4B9629;
    }
  }
}

.nav-datafeed {
  position: relative;
  &:hover {
    .menu {
      display: block;
    }
  }
}
.menu {
  display: none;
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translate(-50%, 100%);
  width: 140px;
  background-color: #fff;
  border-radius: 5px;
  z-index: 999;
  border: 1px solid #3C103A;
  &:before {
    content: '';
    position: absolute;
    top: -13px;
    left: 50%;
    transform: translateX(-50%);
    width:0;height:0;border-style:solid;border-width:0 14px 14px 14px;border-color:transparent transparent #fff transparent;
  }
  &-item {
    display: block;
    height: 30px;
    color: #3C103A;
    text-align: center;
    line-height: 30px;
    &:hover {
      text-decoration: none;
      color: inherit;
    }
    &:first-of-type {
      border-top-right-radius: 5px;
      border-top-left-radius: 5px;
    }
    &:hover {
      background-color: #F8E3F7;
    }
  }
}
.m-menu {
  //width: 140px;
  border-radius: 5px;
  z-index: 999;
  .menu-item {
    display: block;
    height: 30px;
    color: #fff;
    font-size: 14px;
    text-align: center;
    line-height: 30px;
    &:hover {
      text-decoration: none;
      color: inherit;
    }
    &:first-of-type {
      margin-top: 20px;
      border-top-right-radius: 5px;
      border-top-left-radius: 5px;
    }

  }
}


@media (max-width: 768px) {
  .head-nav {
    font-size: 18px;
  }

  .unlock {
    font-size: 18px;
    width: auto;
    word-break: keep-all;
    white-space: nowrap;

  }
  .unlock.buy {
    margin-top: 20px;
    margin-left: 0;
  }
  #header {
    //padding-bottom: 30px;
    .nav-link {
      text-align: center;
      //height: 60px;
      min-height: 60px;
    }
    .ml-100 {
      margin-left: 0;
      margin-bottom: 30px;
    }
  }
  .nav-datafeed {
    &:hover {
      .menu {
        display: none;
      }
    }
    .head-nav {
      display: inline-flex;
    }
  }
  .navbar {
    padding-left: 25px!important;
    padding-right: 25px!important;
  }
}

</style>
