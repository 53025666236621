<template>
  <footer class="footer" id="footer">
    <b-container class="d-flex flex-column align-center">
      <div class="text-center tit">
        <b-img :src="require('../assets/images/foot/foot-t.png')"></b-img>
      </div>
      <div class="cont d-flex justify-center">
        <a class="share" target="_blank" href="https://twitter.com/berry_data">
          <b-img class="ico" :src="require('../assets/images/foot/twitter.png')"></b-img>
          <span>Twitter</span>
        </a>
        <a class="share" target="_blank" href="https://t.me/berrydata">
          <b-img class="ico" :src="require('../assets/images/foot/tel.png')"></b-img>
          <span>Telegram</span></a>
        <a class="share" target="_blank" href="https://medium.com/berry-data-official">
          <b-img class="ico" :src="require('../assets/images/foot/m.png')"></b-img>
          <span>Medium</span></a>
        <a class="share" target="_blank" href="https://github.com/berrydata">
          <b-img class="ico" :src="require('../assets/images/foot/g.png')"></b-img>
          <span>Github</span>
        </a>
        <a class="share" target="_blank" href="https://www.coingecko.com/en/coins/berry-data">
          <b-img class="ico" :src="require('../assets/images/foot/ge.png')"></b-img>
          <span>CoinGecko</span>
        </a>
      </div>

      <div class="copyright0">© 2021 berrydata.co</div>

      <div class="copyright">Contact: <a href="mailto:info@berrydata.co" target="_blank">info@berrydata.co</a></div>

    </b-container>
  </footer>
</template>

<script>
export default {
  name: "my-footer"
}
</script>

<style lang="less" scoped>
.footer {
  height: 557px;
  background: #180217;
  .container {
    height: 100%;
    background: #180217 url("../assets/images/foot/foot.png") bottom center no-repeat;
  }
  .tit {
    padding: 87px 0 75px;
  }
  .cont {
    .share {
      width: 118px;
      height: 40px;
      border-radius: 40px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      background-color: #880D85;
      margin-right: 44px;
      cursor: pointer;
      text-decoration: none;
      &:hover {
        background-color: #CA0FC5;
      }
      &:last-of-type {
        margin-right: 0;
      }
      img {
        width: 28px;
        margin-right: 5px;
      }
      span {
        font-size: 14px;
        color: #fff;
      }
    }
  }
  .copyright0 {
    margin-top: 60px;
    color: #fff;
  }

  .copyright {
    margin-top: 30px;
    color: #fff;

    a {
      color: #fff;
      text-decoration: none;
    }
    
  }
}


@media (max-width: 768px) {
  #footer {
    //padding: 50px 30px;
    height: 500px;
    .tit {
      padding-top: 50px;
      padding-bottom: 50px;
      width: 140px;
      img {
        width: 100%;
      }
    }
    .cont {
      flex-wrap: wrap;
      justify-content: space-between;
      padding: 0 30px;
      .share {
        margin-right: 0;
        margin-bottom: 30px;
      }
    }
  }
}
</style>
