import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        redirect: '/home',
    },
    {
        path: '/home',
        name: 'home',
        component: () => import('@/views/home.vue'),
    },
    {
        path: '/mining',
        name: 'mining',
        component: () => import('@/views/farm.vue'),
    },
    {
        path: '/farm',
        name: 'farm',
        component: () => import('@/views/farm.vue'),
    },
    {
        path: '/data-feed/price',
        name: 'dataFeedPrice',
        component: () => import('@/views/dataFeed/price.vue'),
    },
    {
        path: '/data-feed/yield',
        name: 'dataFeedYield',
        component: () => import('@/views/dataFeed/yield.vue'),
    },
]

const router = new VueRouter({
    routes
})

export default router
